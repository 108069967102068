import { useNavigate } from "react-router-dom"
import { routes } from "../constants/Constants";
import { ErrorV1 } from "../contracts/api";
import { useCallback, useMemo } from "react";

export const useHandleError = () => {
    const navigate = useNavigate();

    const errorMapToFunction = useMemo(() => ({
        "FAILED_TO_GET_ACCESS_TOKEN": () => navigate(routes.signOut),
        "FAILED_LOCALSTORAGE": () => navigate(routes.error, { state: { message: "FAILED_LOCALSTORAGE", errorCode: "0x002" } as ErrorV1 }),
        "FAILED_TO_GET_TOKEN_0x003": () => navigate(routes.error, { state: { message: "FAILED_TO_GET_TOKEN", errorCode: "0x003" } as ErrorV1 }),
        "FAILED_TO_GET_TOKEN_0x004": () => navigate(routes.error, { state: { message: "FAILED_TO_GET_TOKEN", errorCode: "0x004" } as ErrorV1 }),
        "FAILED_TO_AUTHENTICATE": () => navigate(routes.error, { state: { message: "FAILED_TO_AUTHENTICATE", errorCode: "0x000" } as ErrorV1 })
    }), [navigate]);

    type SupportedErrorTypes = keyof typeof errorMapToFunction

    const handleError = useCallback((code: SupportedErrorTypes) => {
        return errorMapToFunction[code]();
    }, [errorMapToFunction])

    return {handleError};
}