import React, { createContext } from 'react';

export interface HeaderLink {
    label: string
    onClick: () => void
}

export type HeaderNav = {
    back?: HeaderLink
    close?: HeaderLink
};

type Context = {
    state: HeaderNav;
    setState: React.Dispatch<React.SetStateAction<HeaderNav>>
};

export const AppContext = createContext<Context>({} as Context);