import { useEffect } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import { routes } from "../../constants/Constants";
import { InitOktaMsaClient } from "./OktaClient";
import getEnv from "../../env/environment";

export default function HandleOktaMsaToken() {

    const navigate = useNavigate()
    const oktaAuth = InitOktaMsaClient()

    useEffect(() => {
        const initOktaAuth = async () => {
            try {
                const access_token = oktaAuth.getAccessToken()
                const id_token = oktaAuth.getIdToken()
                if (!access_token) {
                    console.error("Okta access_token not found")
                    navigate(routes.oktaMsaSignIn)
                    return
                }

                const oktaToken = access_token + ":" + id_token
                let redirectUrl = getEnv().URL_MSA_ADMIN
                window.location.assign(`${redirectUrl}/tg-auth-callback?access_token=${oktaToken}`)
            } catch (error) {
                console.error(error)
                localStorage.clear()
                oktaAuth.clearStorage()
                navigate("/error")
            }
        }

        initOktaAuth();
    }, [oktaAuth, navigate])

    return (<Loader />)
}
