import { useLocation } from "react-router-dom";
import { ErrorV1 } from "../contracts/api";
import { type FC, useEffect } from "react";
import { trackEvent } from "../utils/analytics";
import { useCreateErrorComponent } from "../hooks/useCreateErrorComponent";

const Error:FC = () => {
    useEffect(() => {
        document.title = "Error | TheGuarantors"
        trackEvent('Error Page Loaded', {
            referrer: window.location.pathname,
        });
    }, []);

    const {getComponent} = useCreateErrorComponent();

    const { state } = useLocation();
    const err = state as ErrorV1;

    return getComponent(err);
}

export default Error;
