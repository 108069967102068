import { ErrorV1 } from "../contracts/api"
import { Result } from "../contracts/common"

export interface Cookie {
    Name: string
    Value: string
    Path: string
    Domain: string
    Expires: string
    RawExpires: string
    MaxAge: number
    Secure: boolean
    HttpOnly: boolean
    SameSite: number
    Raw: string
    Unparsed?: any
}

export const SetCookie = (cookie: Cookie) => {
    document.cookie = `${cookie.Name}=${cookie.Value}; expires=${cookie.Expires}; path=${cookie.Path}; domain=${cookie.Domain};`;
}

export const SetCookieRaw = (name: string, value: string, path: string) => {
    document.cookie = `${name}=${value}; path=${path}; domain=theguarantors.com; samesite=Strict; secure=true;`;
}

export interface SignedLinkParameters {
    Expires: string
    KeyName: string
    Signature: string
}

export interface VerifyEmailSignedLinkParameters extends SignedLinkParameters {
    email: string
    migration_id: string
}

export interface SetPasswordSignedLinkParameters extends SignedLinkParameters {
    redirect_uri: string
    user_id: string
    terms?: boolean
}

export function ValidateSignedLink<T extends SignedLinkParameters>(searchParams: URLSearchParams): Result<T, ErrorV1> {
    if (searchParams === undefined) {
        return new Result(undefined as any as T, new ErrorV1(401, "SIGNED_LINK_INVALID"))
    }

    const spsu = Object.fromEntries([...searchParams]) as any as T

    if (spsu.Expires === undefined ||
        spsu.KeyName === undefined ||
        spsu.Signature === undefined) {
        return new Result(undefined as any as T, new ErrorV1(401, "SIGNED_LINK_INVALID"))
    }

    const expires = parseInt(spsu.Expires)
    const utcNow = Date.now() / 1000
    if ((utcNow > expires)) {
        return new Result(undefined as any as T, new ErrorV1(401, "SIGNED_LINK_EXPIRED"))
    }

    return new Result(spsu)
}