import * as yup from "yup";
import { SchemaOf } from "yup";

const emailValidation = yup.string().email("Must be a valid email").required("Email is a required field")
const legacyPasswordValidation = yup.string().required("Password is a required field").min(6, 'Password must be at least 6 characters')
const passwordValidation = yup.string().required("Password is a required field").min(10, 'Password must be at least 10 characters').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, 'Password must contain at least one lowercase, one uppercase and one numeric character')
const termsAcceptedValidation = yup.bool().required("Accepting terms is required").isTrue("Accepting terms is required")
const firstNameValidation = yup.string().required()
const lastNameValidation = yup.string().required()
const termsVersionValidation = yup.string().required()

export interface LandlordMigrationForm {
    firstName?: string
    lastName?: string
    companyName?: string
    landlordEmail?: string
    migrationId?: string
    isMigrated?: boolean
    isOwner?: boolean
    password?: string
    userEmail?: string
}

export interface RenterMigrationForm {
    migrationId?: string
    isMigrated?: boolean
    password?: string
    userEmail?: string
    firstName?: string
    lastName?: string
}

export interface SubmitEmailDto {
    email: string
}

export const SubmitEmailSchema: SchemaOf<SubmitEmailDto> = yup.object({
    email: emailValidation,
})

export interface SubmitPasswordDto {
    password: string
}

export const SubmitPasswordSchema: SchemaOf<SubmitPasswordDto> = yup.object({
    password: legacyPasswordValidation
})

export interface UserLogin {
    email: string
    password: string
}

export const UserLoginSchema: SchemaOf<UserLogin> = yup.object({
    email: emailValidation,
    password: passwordValidation
})

export interface SetPasswordDto {
    password: string
    confirmPassword: string
    acceptedTerms: boolean
    termsVersion: string
}

export interface CreateAccountDto {
    email: string
    password: string
    confirmPassword: string
    acceptedTerms: boolean
    termsVersion: string
}

export interface CompleteAccountSetupDto {
    firstName: string
    lastName: string
    password: string
    confirmPassword: string
    acceptedTerms: boolean
    termsVersion: string
}

export const SetPasswordSchema: SchemaOf<SetPasswordDto> = yup.object({
    password: passwordValidation,
    confirmPassword: passwordValidation.oneOf([yup.ref("password")], 'Passwords do not match'),
    acceptedTerms: termsAcceptedValidation,
    termsVersion: termsVersionValidation,
})

export const CreateAccountSchema: SchemaOf<CreateAccountDto> = yup.object({
    email: emailValidation,
    password: passwordValidation,
    confirmPassword: passwordValidation.oneOf([yup.ref("password")], 'Passwords do not match'),
    acceptedTerms: termsAcceptedValidation,
    termsVersion: termsVersionValidation,
})

export const CompleteAccountSetupSchema: SchemaOf<CompleteAccountSetupDto> = yup.object({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    password: passwordValidation,
    confirmPassword: passwordValidation.oneOf([yup.ref("password")], 'Passwords do not match'),
    termsVersion: termsVersionValidation,
    acceptedTerms: termsAcceptedValidation,
})

export interface SignUpDto extends CompleteAccountSetupDto {
    email: string
    acceptedTerms: boolean
    termsVersion: string
}

export interface SetupAccountDto extends CompleteAccountSetupDto {
    email: string
}

export const SetupAccountSchema: SchemaOf<SetupAccountDto> = yup.object({
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    email: emailValidation,
    password: passwordValidation,
    confirmPassword: passwordValidation.oneOf([yup.ref("password")], 'Passwords do not match'),
    termsVersion: termsVersionValidation,
    acceptedTerms: termsAcceptedValidation,
})

export interface SelectRoleDto {
    owner_type: string
}

export const SelectRoleSchema: SchemaOf<SelectRoleDto> = yup.object({
    owner_type: yup.string().required()
})