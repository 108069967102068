import { useCallback } from "react"
import Default from "../components/Error/Default";
import LogoutInstructions from "../components/Error/LogoutInstructions";
import { ErrorV1 } from "../contracts/api";

const presetErrorToContentMap = (errorCode?: string) => ({
    "SIGNED_LINK_EXPIRED": {
        title: "Your link has expired",
        subtitle: `The link you recently requested has expired. Please ensure you are using the correct link or request a new one. ${errorCode ?? ''}`
    },
    "SET_PASSWORD_SIGNED_LINK_USED": {
        title: "You've already set a password",
        subtitle: `You can log in or reset your password by going to the Sign-In page. ${errorCode ?? ''}`
    },
    "SET_PASSWORD_SIGNED_LINK_EXPIRED": {
        title: "Your link has expired",
        subtitle: `You can still set a password by clicking 'Forgot Password' on the Sign-In page. ${errorCode ?? ''}`
    },
    "SIGNED_LINK_INVALID": {
        title: "Your link is invalid",
        subtitle: `The link you received appears to be invalid. Please ensure you are using the correct link or request a new one. ${errorCode ?? ''}`
    }
})

export const useCreateErrorComponent = () => {
    const errorToComponent = useCallback((message?: string, errorCode?: string) => {
        switch (message) {
            case "SIGNED_LINK_EXPIRED":
            case "SET_PASSWORD_SIGNED_LINK_USED":
            case "SET_PASSWORD_SIGNED_LINK_EXPIRED":
            case "SIGNED_LINK_INVALID":
                return <Default {...presetErrorToContentMap(errorCode)[message]}/>
                
            case "FAILED_LOCALSTORAGE":
            case "FAILED_TO_AUTHENTICATE":
            case "FAILED_TO_GET_TOKEN":
            case "FAILED_TO_EXCHANGE_CODE":
                return <LogoutInstructions errorCode={errorCode}/>
            
            default:
                return <Default
                    title={"Uh oh, an error occurred"}
                    subtitle={"We're sorry, but we experienced an unexpected error. Please try again or contact our support team for assistance if the problem persists."}
                />
          }
    }, []);
    
    const getComponent = useCallback((state: ErrorV1) => {
        return errorToComponent(state?.message, state?.errorCode)
    }, [errorToComponent])

    return { getComponent }
}