import { useNavigate } from "react-router-dom";
import { HeaderLayoutCenter } from "../components/HeaderLayoutCenter";
import Terms from "../constants/Terms";
import { Box } from "@mui/material";
import { getTokenAsync, setTermsVersionAsync } from "../lib/api";
import getEnv from "../env/environment";
// import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { ErrorV1 } from "../contracts/api";
import useProgressStore from "../store/progressStore";
import { trackEvent } from "../utils/analytics";


export default function AcceptTermsPage() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setProgress } = useProgressStore();

  useEffect(() => {
    setProgress(75);
    trackEvent('Accept Terms Page Loaded', {
      referrer: window.location.pathname,
    });
  }, [setProgress]);

  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (
    accessToken === null ||
    accessToken.length === 0 ||
    refreshToken === null ||
    refreshToken.length === 0
  ) {
    console.error("Access or refresh token are null or empty");
    navigate("/error");
  }

  const submit = () => {
    setIsSubmitting(true);

    (async () => {
      let result = await setTermsVersionAsync(
        getEnv().CURRENT_TERMS_VERSION,
        accessToken!
      );

      if (!result.Success) {
        console.error(result.Error);
        navigate("/error", { state: result.Error!.message });
        return;
      }

      const getTokenResult = await getTokenAsync(accessToken!);

      if (
        getTokenResult.Success &&
        getTokenResult.Value?.terms_version === undefined
      ) {

        let redirectUrl = getEnv().URL_SMB
        if (!!getTokenResult.LocationHeader && getTokenResult.LocationHeader.length > 0) {
          redirectUrl = getTokenResult.LocationHeader!
        }

        window.location.assign(
          `${redirectUrl
          }?access_token=${accessToken!}&refresh_token=${refreshToken!}`
        );
        return;
      }

      console.error("Failed to get token");
      localStorage.clear();
      navigate("/error", {
        state: { message: "FAILED_TO_GET_TOKEN" } as ErrorV1,
      });
    })();
  };

  return (
    <HeaderLayoutCenter
      title="Terms & Conditions"
      subtitle={`Please confirm the terms and conditions for your account`}
      maxWidth={700}
      children={
        <>
          <Box
            sx={{
              overflow: "scroll",
              height: "50vh",
              p: 2,
              bgcolor: "rgba(61, 177, 87, .15)",
              lineHeight: "24px",
              borderRadius: "4px",
              a: {
                color: "#777777",
              },
            }}
          >
            <Terms />
          </Box>
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            onClick={submit}
            disabled={isSubmitting}
            loading={isSubmitting}
            sx={{ mt: 2, marginX: "auto", display: "block" }}
          >
            I Agree
          </LoadingButton>
        </>
      }
    />
  );
}
