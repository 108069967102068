import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Buffer } from 'buffer';
import { AuthAppState } from "../../contracts/common";
import { trackEvent } from "../../utils/analytics";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/Constants";
import { usePeekAuth0SessionStorage } from "../../hooks/usePeekAuth0SessionStorage";
import { useLoginEvents } from "../../hooks/useLoginEvents";
import { Stage } from "../../utils/enums/stage";

interface RedirectToLoginProps {
    returnToMsa?: boolean
}

export default function RedirectToLogin(props: RedirectToLoginProps) {
    const { loginWithRedirect, isLoading, isAuthenticated} = useAuth0();

    const {successEvent} = useLoginEvents();

    usePeekAuth0SessionStorage('RedirectToLogin');

    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        console.log("location pathname:", location.pathname);
        console.log("window location:", window.location.pathname);
    }, [location]);

    useEffect(() => {
        trackEvent('Login page Loaded', {
            referrer: window.location.pathname,
        });
    },[])

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isAuthenticated) {
            navigate(routes.callback);
            return;
        }

        const initAuth0 = async () => {
            if (props.returnToMsa === true) {
                const appState: AuthAppState = {
                    returnToMsa: props.returnToMsa
                }

                const state = Buffer.from(JSON.stringify(appState), 'utf8').toString('base64')
                const options = {
                    appState: {
                        appState: state
                    },
                }

                console.log("auth0 debug: loginWithRedirect called", options);
                await loginWithRedirect(options)
            } else {
                console.log("auth0 debug: loginWithRedirect called");
                await loginWithRedirect()
            }

            successEvent(Stage.START_REDIRECT_TO_LOGIN);
        }

        initAuth0()
    }, [loginWithRedirect, isLoading, isAuthenticated, navigate, props, successEvent])

    return (<></>)
}