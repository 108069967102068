import { datadogLogs } from "@datadog/browser-logs";
import { Stage } from "../utils/enums/stage";

type Status = 'info' | 'error'

const logDatadogEvent = (status: Status, stage: Stage, customMetadata: Object) => {
    datadogLogs.logger[status]('Login Event', {
        stage,
        customMetadata,
    });
};

export const useLoginEvents = () => {
    return {
        successEvent: (stage: Stage, metadata?: Object) => logDatadogEvent('info', stage, metadata ?? {}), 
        errorEvent: (stage: Stage, metadata: Object) => logDatadogEvent('error', stage, metadata)
    };

}