import {
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useContext } from "react";
import TheGuarantorsLogo from "../assets/images/updated-logo.png";
import { AppContext } from "../lib/context";
import { Close, ArrowBack } from "@mui/icons-material/";
import useProgressStore from "../store/progressStore";

export default function SiteHeader() {
  const { state } = useContext(AppContext);
  const { progress } = useProgressStore();

  return (
    <Box sx={{ display: "block", height: "17vh" }}>
      <LinearProgress
        sx={{ display: progress ? "fixed" : "none", position: "fixed" }}
        value={progress}
      />
      <Box sx={{ margin: "20px 20px 0 20px", position: "relative" }}>
        <AppBar
          component="nav"
          sx={{
            pr: 0,
            boxShadow: "4px 16px 32px rgba(0, 0, 0, 0.08)",
            position: "relative",
            height: "100%",
          }}
        >
          <Toolbar
            sx={{
              backgroundColor: "white",
              color: "black",
              height: "80px",
              borderRadius: "8px",
            }}
          >
            <Box width={100} display={"block"}>
              {state.back && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  onClick={state.back.onClick}
                  sx={{
                    height: "100%",
                    verticalAlign: "middle",
                    "&:hover": {
                      cursor: "pointer",
                      "& .MuiSvgIcon-fontSizeMedium": {
                        stroke: "#080a2d",
                        color: "white",
                        backgroundColor: "#080a2d",
                      },
                    },
                  }}
                >
                  <ArrowBack
                    sx={{
                      mr: 1,
                      p: "6px",
                      height: 26,
                      width: 26,
                      stroke: "white",
                      border: "1px solid #080a2d",
                      borderRadius: "50%",
                      transition: "all .2s ease-in-out",
                    }}
                  />
                  <Typography>{state.back.label}</Typography>
                </Stack>
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
              }}
            />
            <img height="24px" src={TheGuarantorsLogo} alt="TheGuarantors" />
            <Box sx={{ flexGrow: 1 }} />
            <Box width={100} display={"block"} textAlign={"right"}>
              {state.close && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  display={"inline-flex"}
                  onClick={state.close.onClick}
                  sx={{
                    height: "100%",
                    verticalAlign: "middle",
                    "&:hover": {
                      cursor: "pointer",
                      "& .MuiSvgIcon-fontSizeMedium": {
                        stroke: "#080a2d",
                        color: "white",
                        backgroundColor: "#080a2d",
                      },
                    },
                  }}
                >
                  <Typography>{state.close.label}</Typography>
                  <Close
                    sx={{
                      ml: 1,
                      p: "6px",
                      height: 26,
                      width: 26,
                      stroke: "white",
                      border: "1px solid #080a2d",
                      borderRadius: "50%",
                      transition: "all .2s ease-in-out",
                    }}
                  />
                </Stack>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
}
