export class Result<TSuccess, TFail> {
    public Value?: TSuccess
    public Error?: TFail
    public Success: boolean
    public LocationHeader?: string

    constructor(value?: TSuccess, error?: TFail, locationHeader?: string) {
        this.Success = error === undefined
        this.Error = error
        this.Value = value
        this.LocationHeader = locationHeader
    }
}

export interface MigrationProps<T> extends StepProps<T> {
    userEmail?: string
    firstName?: string
    lastName?: string
    landlordOrgName?: string
    landlordEmail?: string
}

export interface StepProps<T> {
    next: (item: T) => void
}

export const enum StepType {
    HOME = "HOME",
    SUBMIT_EMAIL = "SUBMIT_EMAIL",
    SUBMIT_PASSWORD = "SUBMIT_PASSWORD",
    SET_NEW_PASSWORD_OWNER = "SET_NEW_PASSWORD_OWNER",
    SET_NEW_PASSWORD_TEAM = "SET_NEW_PASSWORD_TEAM",
    SELECT_ROLE = "CHOOSE_ROLE",

    EMAIL_NOT_RECOGNIZED = "EMAIL_NOT_RECOGNIZED",
    VERIFY_EMAIL_SENT = "VERIFY_EMAIL_SENT",
    FINISHED = "FINISHED",
}

export interface AuthAppState {
    returnToMsa: boolean
}