import SiteFooter from "./SiteFooterV2";
import SiteHeader from "./SiteHeader";

interface Props {
  children: JSX.Element[] | JSX.Element;
}

export default function PageContainer({ children }: Props) {

  return (
    <>
      <SiteHeader />
      <div style={{ marginBottom: 30 }}>{children}</div>
      <div style={{ flexGrow: 1, flexDirection: "column" }}></div>
      <SiteFooter />
    </>
  );
}
