import { stepConnectorClasses } from "@mui/material";
import checkboxClasses from "@mui/material/Checkbox/checkboxClasses";
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from "@mui/material/styles";

const isFirefox = typeof (window as any).InstallTrigger !== "undefined";

const breakpoints = {
  xs: 0 as number,
  sm: 600 as number,
  md: 900 as number,
  lg: 1200 as number,
  xl: 1536 as number,
};

declare module "@mui/material" {
  interface ButtonPropsVariantOverrides {
    faq: true;
  }
}

const theme: ThemeOptions = {
  transitions: {
    duration: {
      enteringScreen: 750,
      leavingScreen: 500,
    },
  },
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    primary: {
      main: "#080A2D",
      light: "#FFF",
      dark: "#FFF",
      contrastText: "#000",
    },
    secondary: {
      main: "#3DB157",
      light: "rgba(61, 177, 87, 0.1)",
      dark: "rgba(61, 177, 87, 0.2)",
    },
    text: {
      secondary: "#777777",
      disabled: "#A6A6BF",
    },
    error: {
      main: "#FF3030",
    },
    grey: {
      50: "#080A2D14",
      100: "#1D1D1D",
      200: "#858693",
      600: "rgba(8, 10, 45, 0.12)",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true as boolean,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          padding: isFirefox ? "16px 30px 13px" : "13px 30px 17px",
          border: "1px solid",
          fontSize: "16px",
          borderRadius: "10px",
          [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
            width: "100%",
            padding: "13px",
          },
          ...(ownerState.variant === "contained" && {
            color: "#FFF",
            "&:hover": {
              color: "#000000",
            },
            "&:disabled": {
              color: "#FFF",
              backgroundColor: "#7D7F90",
            },
          }),
          ...(ownerState.variant === "outlined" && {
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#080A2D",
            },
          }),
          ...(ownerState.variant === "text" && {
            borderColor: "white",
            padding: "5px 10px",
            [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
              width: "unset",
              padding: "unset",
            },
          }),
          ...(ownerState.size === "small" && {
            fontSize: "14px" as string,
            padding: "15px 30px",
            [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
              width: "auto",
            },
          }),
        }),
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        variant: "determinate",
        color: "success",
      },
      styleOverrides: {
        root: () => ({
          backgroundColor: "#F8F4EF",
          height: "10px",
          width: "100%",
          zIndex: "100",
        }),
        bar: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "rgb(119, 119, 119)",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          text: {
            fontFamily: "Arial",
            fill: "white",
          },
          color: "#080A2D",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          left: "calc(-27% + 20px)",
          right: "calc(73% + 20px)",
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#080A2D",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: { disableUnderline: true },
        variant: "filled",
        margin: "dense",
        fullWidth: true,
      },
      styleOverrides: {
        root: () => ({
          "& .Mui-error": {
            color: "#FF3030",
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "rgba(119, 119, 119, 1)",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#ECF7EE!important",
          borderRadius: 4,
          border: "1px solid #FFF",
          "&:hover": {
            backgroundColor: "rgba(61, 177, 87, .15)!important",
            border: "1px solid #1D1D1D",
          },
          "&.Mui-error": {
            border: "1px solid rgba(255, 48, 48, 1)!important",
          },
          "&::before": {
            borderBottom: "1px solid #FFF",
          },
          "&::after": {
            borderBottom: "1px solid white!important",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: () => ({
          textTransform: "none",
          ...{
            alignItems: "center",
            padding: "16px",
            border: "1px solid #E0E0E0",
            boxShadow: "0px 0px 0px 1px #E0E0E0",
            borderRadius: "4px",
            "&$checked": {
              // backgroundColor: '#3DB157'
            },
            transition:
              "color .2s ease-in-out,background-color .2s ease-in-out",
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#777777",
          [`&.${checkboxClasses.checked}`]: {
            color: "#3DB157",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Acid Grotesk" as string,
    allVariants: {
      color: "rgba(29, 29, 29, 1)" as string,
    },
    h1: {
      fontFamily: "Ivar Display" as string,
      fontSize: "72px" as string,
      [`@media screen and (max-width: ${breakpoints.md}px)`]: {
        fontSize: "40px",
      },
    },
    h2: {
      fontFamily: "Ivar Display" as string,
      fontSize: "64px" as string,
      fontWeight: 400,
      letterSpacing: "-0.02em",
      [`@media screen and (max-width: ${breakpoints.md}px)`]: {
        fontSize: "36px",
      },
    },
    h3: {
      fontFamily: "Ivar Display" as string,
      fontSize: "42px" as string,
      [`@media screen and (max-width: ${breakpoints.md}px)`]: {
        fontSize: "28px",
      },
    },
    h4: {
      fontFamily: "Ivar Display" as string,
      fontSize: "32px" as string,
      fontWeight: 400 as number,
      [`@media screen and (max-width: ${breakpoints.md}px)`]: {
        fontSize: "22px",
      },
    },
    h5: {
      fontSize: "24px" as string,
      [`@media screen and (max-width: ${breakpoints.md}px)`]: {
        fontSize: "20px",
      },
    },
    h6: {
      fontSize: "20px" as string,
      fontWeight: 400 as number,
      [`@media screen and (max-width: ${breakpoints.md}px)`]: {
        fontSize: "18px",
      },
    },
    subtitle1: {
      fontSize: "20px" as string,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: "18px" as string,
    },
    body1: {
      fontSize: "16px" as string,
    },
    body2: {
      fontSize: "14px" as string,
    },
    caption: {
      fontSize: "12px" as string,
      opacity: 0.5,
    },
  },
};

export const materialTheme = responsiveFontSizes(createTheme(theme));
