import { useTheme, Box, Divider, Typography, Link } from "@mui/material";
import Logo from "./../assets/images/footer-logo.png";
import { externalLinks, footerMenu, SocialLinks } from "../constants/Constants";
import { SvgIcon } from "@mui/material";
import Wechat from "./../assets/images/wechat.svg";
import getEnv from "../env/environment";

const SiteFooterV2 = () => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        width: "100%",
        mt: { xs: "40px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-between" },
          px: { xs: 3.75, lg: 7.5 },
          py: { xs: 5, lg: 10 },
        }}
      >
        <Box
          component="img"
          sx={{
            [theme.breakpoints.down("lg")]: {
              width: "315px",
              height: "40px",
            },
            width: "700px",
            height: "90px",
          }}
          src={Logo}
          alt="TheGuarantors"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            gap: "15px",
            marginTop: { xs: "50px", sm: "0px" },
          }}
        >
          {SocialLinks.map((item, index) => (
            <Link
              key={index}
              target="_blank"
              href={item.link}
              underline="hover"
              color={theme.palette.primary.light}
              role="footerLinks"
            >
              <SvgIcon aria-label={item.title} aria-hidden="false">
                {item.icon}
              </SvgIcon>
            </Link>
          ))}
          <Link
            target="_blank"
            href={`${getEnv().MARKETING_WEBSITE}/wechat`}
            underline="hover"
            color={theme.palette.primary.light}
            role="footerLinks"
          >
            <img src={Wechat} alt="wechat" height="24px" width="24px" />
          </Link>
        </Box>
      </Box>
      <Divider
        sx={{
          opacity: 0.5,
          border: `0.5px solid ${theme.palette.primary.light}`,
        }}
      />

      <Box sx={{ px: { xs: 3.75, md: 7.5 } }}>
        <Box pt="25px">
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: { xs: "300px", sm: "650px" },
              fontSize: { xs: "14px", lg: "16px" },
            }}
          >
            TheGuarantors is a licensed insurance agent and broker. For more
            detailed information, please see our{" "}
            <Link
              underline="always"
              color="text.secondary"
              target="_blank"
              href={externalLinks.legalRegulatory}
            >
              Legal/Regulatory Notice.
            </Link>
          </Typography>
        </Box>
        <Box py="30px">
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              flexDirection: { lg: "row-reverse" },
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: { xs: theme.spacing(2), md: theme.spacing(5, 2), lg: 5 },
                flexWrap: "wrap",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
            >
              {footerMenu.map((item, index) => (
                <Link
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      width: "45%",
                    },
                    fontSize: { xs: "14px", lg: "16px" },
                    ":hover": { color: "primary.light" },
                  }}
                  key={index}
                  variant="body1"
                  color="text.secondary"
                  target="_blank"
                  href={item.to}
                  underline="hover"
                >
                  {item.title}
                </Link>
              ))}
            </Box>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontSize: { xs: "14px", lg: "16px" } }}
            >
              © {currentYear} TheGuarantors
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SiteFooterV2;
