import { useEffect } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import { routes } from "../../constants/Constants";
import { InitOktaClient } from "./OktaClient";
import { getTokenAsync } from "../../lib/api";
import getEnv from "../../env/environment";

export default function HandleOktaToken() {

    const navigate = useNavigate()
    const oktaAuth = InitOktaClient()

    useEffect(() => {
        const initOktaAuth = async () => {
            try {
                const access_token = oktaAuth.getAccessToken()
                const id_token = oktaAuth.getIdToken()
                if (!access_token) {
                    console.error("Okta access_token not found")
                    navigate(routes.oktaSignIn)
                    return
                }

                const oktaToken = access_token + ":" + id_token
                const getTokenResult = await getTokenAsync(oktaToken)

                if (getTokenResult.Success) {
                    if (getTokenResult.Value?.terms_version !== undefined) {
                        navigate(routes.acceptTerms)
                        return
                    }

                    let redirectUrl = getEnv().URL_ADMIN_PORTAL
                    window.location.assign(`${redirectUrl}?access_token=${oktaToken}`)
                    return
                }

                console.error('Failed to get token')
                localStorage.clear()
                oktaAuth.clearStorage()
                navigate("/error")
            } catch (error) {
                console.error(error)
                localStorage.clear()
                oktaAuth.clearStorage()
                navigate("/error")
            }
        }

        initOktaAuth();
    }, [oktaAuth, navigate])

    return (<Loader />)
}
