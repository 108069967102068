import { Typography, Stack, Box, Fade } from '@mui/material';

export const HeaderLayoutCenter = ({
    title,
    subtitle,
    maxWidth,
    children,
}: {
    title: string;
    subtitle?: string;
    maxWidth?: number;
    children: JSX.Element[] | JSX.Element;
}) => {
    return (
        <Fade in={true}>
    <Box sx={{ textAlign: '-moz-center' }}>
            <Box sx={{ textAlign: '-webkit-center' }}>
                <Stack
                    alignItems="center"
                    spacing={{ xs: 3, md: 0 }}
                    maxWidth={1200}
                    justifyContent="space-between"
                    direction={{ xs: 'column' }}
                    mx={2}
                >
                    <Typography variant="h2" align="center" id={`HeaderLayout-title-${title.replace(/[\W_]+/g, '_')}`} sx={{ mb: 2 }} maxWidth={maxWidth ?? 500}>
                        {title}
                    </Typography>
                    <Typography align="center" id={`HeaderLayout-subtitle-${subtitle}`} sx={{ mb: '20px !important' }} maxWidth={maxWidth ?? 500}>
                        {subtitle}
                    </Typography>
                    <Box width={'100%'}>
                        <Box sx={{ px: 3, maxWidth: maxWidth ?? '500'}}>
                            {children}
                        </Box>
                    </Box>
                </Stack>
            </Box>
    </Box>
        </Fade>

    );
};
