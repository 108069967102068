import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TitleAndImageWrapper from "../components/TitleAndImageLayout";
import { routes } from "../constants/Constants";
import { ImageType } from "../constants/Image";
import useProgressStore from "../store/progressStore";
import { useEffect } from "react";
import { trackEvent } from "../utils/analytics";

export default function YouAreAllSet() {
  const navigate = useNavigate();
  const { setProgress } = useProgressStore();

  useEffect(() => {
    document.title = "Password success | TheGuarantors"
    setProgress(100);
    trackEvent("You're all set Page Loaded", {
      referrer: window.location.pathname,
    });
  }, [setProgress]);

  return (
    <TitleAndImageWrapper
      title="You're all set"
      subtitle="Your account has been verified. You can now continue to your account dashboard."
      image={ImageType.FRAME_SCENE}
      maxWidth={700}
      showProgress={true}
      children={
        <Box alignItems={"center"}>
          <Button
            variant="contained"
            type="submit"
            onClick={() => {
              trackEvent('Go to Sign in', {
                referrer: window.location.pathname,
              });
              navigate(routes.signIn);
            }}
            sx={{ mt: 2, marginX: "auto", mr: 2 }}
          >
            Go to Sign In
          </Button>
        </Box>
      }
    />
  );
}
