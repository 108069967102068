export enum Stage {
    LOGOUT = 'LOGOUT',
    READ_ACCESS_TOKEN = 'READ_ACCESS_TOKEN',
    READ_REFRESH_TOKEN = 'READ_REFRESH_TOKEN',
    FETCH_EXTERNAL_TOKEN = 'FETCH_EXTERNAL_TOKEN',
    SAVE_EXTERNAL_TOKEN = 'SAVE_EXTERNAL_TOKEN',
    AUTHENTICATED = 'AUTHENTICATED',
    GENERAL = 'GENERAL',
    START_HANDLE_CALLBACK = 'START_HANDLE_CALLBACK',
    START_REDIRECT_TO_LOGIN = 'START_REDIRECT_TO_LOGIN' 
}