import { useEffect } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";
import { routes } from "../../constants/Constants";
import { InitOktaClient } from "./OktaClient";

export default function HandleOktaCallback() {

    const navigate = useNavigate()
    const oktaAuth = InitOktaClient()

    useEffect(() => {

        const initOktaAuth = async () => {
            await oktaAuth.handleRedirect()
            const access_token = oktaAuth.getAccessToken()
            if (access_token === undefined) {
                navigate("/error", { state: "Contact the Auth team for assistance." })
                return
            }

            navigate(routes.oktaToken)
        }

        initOktaAuth();
    }, [oktaAuth, navigate])

    return (<Loader />)
}