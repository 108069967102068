import { Typography, Box, Stack, Button } from "@mui/material";
import { GetImage, ImageType } from "../constants/Image";
import { useLocation } from "react-router-dom";
import { routes } from "../constants/Constants";

const SignUpThankyouPage = () => {
  const { state } = useLocation();

  let email = !!state ? (state as string) : "";

  return (
    <Box px={2}>
      <Stack
        direction="column"
        alignContent="center"
        alignItems="center"
        maxWidth={600}
        marginX={"auto"}
      >
        <Typography
          mb={2}
          variant="h2"
          align="center"
          id={`HeaderLayout-title-Check_your_email`}
        >
          Thankyou
        </Typography>
        <Typography align="center">
          For your security, a confirmation has been sent to your email:
        </Typography>
        <Typography align="center" mt={1}>
          <u>{email}</u>
        </Typography>
        <Typography align="center" mt={4}>
          Please follow the instructions in the email to proceed and thank you
          for choosing TheGuarantors.
        </Typography>
        <Box alignItems={"center"} mb={4}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={{ mt: 2, marginX: "auto" }}
            onClick={() => window.location.replace(routes.home)}
          >
            Back To Homepage
          </Button>
        </Box>
        <img
          src={GetImage(ImageType.FRAME_SCENE)}
          alt={ImageType.FRAME_SCENE}
        />
      </Stack>
    </Box>
  );
};
export default SignUpThankyouPage;
