import { useAuth0 } from "@auth0/auth0-react";
import getEnv from "../env/environment";

export const usePeekAuth0SessionStorage = (label?: string) => {
    const { isLoading } = useAuth0();

    if (isLoading) {
        let data = sessionStorage.getItem(`${`a0.spajs.txs`}.${getEnv().AUTH0_CLIENT_ID}`); //a0.spajs.txs.GPfQDqDyjdpjZwS5TLYRjfnSUHFA9WDE
        console.log("===== CHECK SESSION DATA ======", label, data);
    }
}