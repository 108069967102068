import img_main_logo from '../assets/images/logo.svg'
import img_stairs from '../assets/images/stairs.png'
import img_frame from '../assets/images/frame.png'
import img_danger from '../assets/images/danger.png'
import img_warning from '../assets/images/warning.png'
import img_danger_icon from '../assets/images/danger-icon.png'
import img_info_icon from '../assets/images/info-icon.png'
import img_logo_icon from '../assets/images/logo-icon.png'
import img_frame_scene from '../assets/images/frame-scene.png'
import img_frame_couple from '../assets/images/frame-couple.png'

export enum ImageType {
    LOGO = "hero--logo",
    STAIRS = "hero--stairs",
    FRAME = "hero--frame",
    FRAME_SCENE = "hero--frame-scene",
    FRAME_COUPLE = "hero--frame-couple",
    DANGER = "hero--danger",
    WARNING = "hero--warning",
    DANGER_ICON = "hero--danger-icon",
    INFO_ICON = "hero--info-icon",
    LOGO_ICON = "hero--logo-icon",
}

export const GetImage = (image: ImageType) => {
    switch (image) {
        case ImageType.LOGO: return img_main_logo
        case ImageType.STAIRS: return img_stairs
        case ImageType.FRAME: return img_frame
        case ImageType.FRAME_SCENE: return img_frame_scene
        case ImageType.FRAME_COUPLE: return img_frame_couple
        case ImageType.DANGER: return img_danger
        case ImageType.WARNING: return img_warning
        case ImageType.DANGER_ICON: return img_danger_icon
        case ImageType.INFO_ICON: return img_info_icon
        case ImageType.LOGO_ICON: return img_logo_icon
    }
}