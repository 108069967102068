import { AnalyticsBrowser } from '@segment/analytics-next';
import getEnv from '../env/environment';

export const analytics = AnalyticsBrowser.load({ writeKey: getEnv().SEGMENT_API_KEY });

let prevPath: string | null = null;

function trackCurrentPage(pathname: string) {
  if (pathname !== prevPath) {
    prevPath = pathname;
  }
}

function trackEvent(eventName: string, payload: { [key: string]: any }) {
  analytics.track(eventName, {
    ...payload,
  });
}



export { trackCurrentPage, trackEvent };
