import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useProgressStore = create(
  devtools<{
    progress: number;
    setProgress: (input: number) => void;
  }>((set) => ({
    progress: 0,
    setProgress: (input) => set({ progress: input }),
  }))
);

export default useProgressStore;
